const React = require("react");
const moment = require('moment');
const { useEffect, useState, useMemo } = require("react");
const netlifyIdentity = require("netlify-identity-widget");
const { getStorage, setStorage, removeStorage } = require('@helpers/general');
const blobs = require('@helpers/blobs');
const { getClient, createClient, updateClient, deleteClient } = require('@helpers/blobs');
const faunadb = require('@helpers/faunadb');
const { createTransaction, getClientTransactions, createConversation, getClientConversationsForModule, updateConversation, deleteConversation } = require('@helpers/faunadb');
const atlassian = require('@helpers/atlassian');
const { getDataByType, updateData } = require('@helpers/dataMap');
const { createCustomer } = require('@helpers/stripe');
const { check, calcCost } = require('@helpers/relevanceai');
const { useMediaQuery } = require('@mui/material');
const icons = require('@assets/index');

const IdentifyContext = React.createContext({
    identity: () => { },
    user: '',
    roles: [],
    addRole: (role) => { },
    removeRole: (role) => { },
    loginAs: false,
    // setLoginAs: () => { },
    triggerLoginAs: () => { },
    client: null,
    clientData: null,
    isAdmin: () => { },
    isClient: () => { },
    hasAccess: (module) => { },
    transfer: (data) => { }
});

// const siteDomains = ['https://matter-portal.netlify.app', 'https://portal.matterdesign.com.au']

const IdentityProvider = props => {
    const [isWorking, setWorking] = useState(false);
    const [notify, setNotification] = useState(null);
    const [netlifyInitialised, setNetlifyInitialised] = useState(false);

    const [user, setUser] = useState();
    const [userChecked, setUserChecked] = useState(false);
    const [roles, setRoles] = useState([]);
    const [loginAs, setLoginAs] = useState(false);
    const [client, setClient] = useState(null);
    const [clientData, setClientData] = useState(null);

    const [activeModule, setActiveModule] = useState(null);
    const [dataTransfer, setDataTransfer] = useState({});

    const [conversations, setConversations] = useState(null);
    const [currentConversation, setCurrentConversation] = useState(null);
    const [triggerConversationsRefresh, setTriggerConversationsRefresh] = useState(false);

    const [dataSource, setDataSource] = useState('');
    const [dataInstances, setDataInstances] = useState(null);
    const [dataInstance, setDataInstance] = useState(null);
    const [dataType, setDataType] = useState('');
    const [dataOptions, setDataOptions] = useState(null);
    const [dataItem, setDataItem] = useState(null);
    const [dataFields, setDataFields] = useState(null);
    const [dataField, setDataField] = useState(null);

    /* Working */

    const toggleWorking = (status) => {
        setWorking(status);
    }

    const transfer = (data) => {
        setDataTransfer(data);
    }

    /* Functions Working */

    const atlassianWorking = async (func, ...args) => {
        toggleWorking(true);
        const response = await atlassian[func](...args);
        toggleWorking(false);
        return response;
    }

    const blobsWorking = async (func, ...args) => {
        toggleWorking(true);
        const response = await blobs[func](...args);
        toggleWorking(false);
        return response;
    }

    const faunadbWorking = async (func, ...args) => {
        toggleWorking(true);
        const response = await faunadb[func](...args);
        toggleWorking(false);
        return response;
    }

    /* Notification */

    const triggerNotification = (message) => {
        let _message = message;
        if (typeof _message === "string") {
            _message = { message };
        }
        setNotification(message);
    }

    /* Netlify Auth */

    useEffect(() => {
        if (!netlifyInitialised) {
            netlifyIdentity.init();
            setNetlifyInitialised(true);
        }
    }, [netlifyInitialised]);

    netlifyIdentity.on("login", _user => {
        auth(_user);
        netlifyIdentity.close();
    });
    netlifyIdentity.on("logout", () => {
        setUser();
        setClientData(null);
        setClient(null);
        setRoles([]);
        netlifyIdentity.close();
    });
    netlifyIdentity.on('init', _user => {
        if (_user) {
            // URL path is relative on prod and therefore is unable to be used to determine auth with this site
            // const authForThisSite = siteDomains.find(d => user.url.indexOf(d) > -1);
            // if (authForThisSite) {
            auth(_user);
            // } else {
            //     netlifyIdentity.logout();
            // }
        } else {
            setUserChecked(true);
        }
    });

    /* Portal Auth functions */

    // useEffect(() => {
    //     if (loginAs) {
    //         setClient(loginAs.key);
    //     } else {
    //         if (client) setClient(null);
    //         if (clientData) setClientData(null);
    //     }
    // }, [loginAs]);

    const triggerLoginAs = (value) => {
        if (value) {
            // console.log(value, "value")
            setLoginAs(value);
            setClient(value.key);
            setStorage('_mpc', btoa(JSON.stringify(value)));
        } else {
            if (client) setClient(null);
            if (clientData) setClientData(null);
            removeStorage('_mpc');
        }
    }

    const auth = _user => {
        // console.log(_user, "user")
        setUser(_user);
        const _roles = getRoles(_user);
        // console.log(_roles, "roles")
        setRoles(_roles);
        if (_roles.indexOf('Matter Admin') === -1) {
            // Assuming client key is first array item
            setClient(_roles[0]);
        } else {
            // Is Matter admin - return back to admin view
            // check if in login as session
            const clientSession = getStorage('_mpc');
            if (clientSession) {
                triggerLoginAs(JSON.parse(atob(clientSession)));
            }
            setUserChecked(true);
            // For development purpose - force to defined client
            // setClient('usu');
        }
    }

    /* Client data management */

    //reset conversation everytime a client is switched
    useEffect(() => {
        setConversations(null);
    }, [client])

    useEffect(() => {
        const getClientData = () => {
            getClient(client).then(result => {
                if (String(result.status).startsWith('2')) {
                    const _clientData = JSON.parse(result.response);
                    setClientData(_clientData);
                    setUserChecked(true);
                }
            });
        }

        if (!clientData && client) {
            getClientData();
        }
    }, [client, clientData])

    const addClient = async (key, data) => {
        const result = await createClient(key, data);
        return result;
    }

    const saveClient = async (key, data) => {
        const result = await updateClient(key, data);
        if (clientData && result.status === 200) {
            setClientData(data);
        }
        return result;
    }

    const removeClient = async (key) => {
        const result = await deleteClient(key);
        return result;
    }

    const updateUserMeta = async (data) => {
        const key = user.email;
        const currentClientData = { ...clientData };

        if (!currentClientData.userMeta) {
            currentClientData.userMeta = {};
        }

        if (!currentClientData.userMeta[key]) {
            currentClientData.userMeta[key] = {};
        }

        currentClientData.userMeta[key] = { ...currentClientData.userMeta[key], ...data };

        return await saveClient(currentClientData.key, currentClientData);
    }

    const getCustomerId = async () => {
        const key = user.email;
        const customerId = (clientData?.userMeta && clientData?.userMeta[key]?.stripeId) || null;

        if (!customerId) {
            // customer ID not set - create
            const newCustomerId = await createCustomer(key, user.user_metadata?.full_name || "Undefined", { client: clientData.key });
            await updateUserMeta({ stripeId: newCustomerId.id });
            return newCustomerId.id;
        }

        return customerId;
    }

    const isAdmin = () => {
        return roles.indexOf('Matter Admin') > -1
    }

    const isClient = () => {
        return client !== null;
    }

    const hasAccess = (module) => {
        return clientData && clientData.modules[module];
    }

    const getRoles = (_user) => {
        return _user.app_metadata.roles;
    }

    const addRole = (newRole) => {
        setRoles(r => {
            const newRoles = Array.isArray(newRole) ? newRole : [newRole];
            return [...r, ...newRoles];
        });
    }

    const removeRole = (role) => {
        setRoles(r => {
            const currentRoles = [...r];
            const oldRoles = Array.isArray(role) ? role : [role];
            oldRoles.forEach(r => {
                const roleIndex = currentRoles.indexOf(r);
                currentRoles.splice(roleIndex, 1);
            });
            return currentRoles;
        });
    }

    /* Module control */

    const setModule = (module) => {
        if (activeModule !== module) {
            setConversations(null);
        }
        setActiveModule(module);
    }

    /* Portal credits */

    const addCredits = async (amount, pi, summary, _key) => {
        toggleWorking(true);
        const _client = _key || client;
        await createTransaction(_client, user.email, 'purchase', amount, null, summary, pi);
        if (clientData) {
            const currentCredits = Number(clientData.credits) || 0;
            const newCreditValue = currentCredits + amount;
            const _clientData = { ...clientData, credits: newCreditValue };
            await saveClient(_client, _clientData);
            return _clientData.credits;
        }
        toggleWorking(false);
        return amount;
    }

    const hasCredits = (required) => {
        const clientCredits = clientData.credits || 0;
        return clientCredits >= (required || 0);
    }

    const recalculateCredits = async (_client, _clientData) => {
        const c = _client || client;
        const cd = _clientData || clientData;
        toggleWorking(true);
        const response = await getClientTransactions(c);
        let retracedCredits = 0;
        const transactionList = response.map(t => {
            /* Retrace credit values to confirm against stored */
            retracedCredits = t.type === 'purchase' ? retracedCredits + Number(t.credits || 0) : retracedCredits - Number(t.credits || 0);
            return ({ ...t, credits: Math.ceil(t.credits || 0), date: moment(t.ts.isoString).format('Do MMMM YYYY @ h:mma') })
        });

        if (retracedCredits !== (cd.credits || 0)) {
            /* Fallback to replace the cached value against recorded transactions */
            const newClientData = { ...cd };
            newClientData.credits = retracedCredits;
            await saveClient(c, newClientData);
        }
        toggleWorking(false);

        return { transactionList, retracedCredits };
    }

    const spendCredits = async (amount, summary) => {
        await createTransaction(client, user.email, 'spend', amount, null, summary, null);
        if (clientData) {
            const currentCredits = clientData.credits;
            const newCreditValue = Number(currentCredits) - Number(amount);
            const _clientData = { ...clientData, credits: newCreditValue };
            // Check if top up is active and check new balance is above minimum.
            // If active and found to be below threshold, trigger auto topup
            if (clientData.topup?.active) {
                const topup = clientData.topup;
                if (Number(topup.minimumBalance) > Number(newCreditValue)) {
                    const updatedClientData = await runAutoTopup(_clientData, topup);
                    await saveClient(client, updatedClientData);
                    return updatedClientData.credits;
                }
            }
            await saveClient(client, _clientData);
            return _clientData.credits;
        }

        return amount;
    }

    const runAutoTopup = (_clientData, topup) => {
        // TODO: Process payment for topup amount
        // Issue here is that stripe will want to redirect user after success, which will break the flow for the user
        // This really needs to be a background function
        const currentCredits = _clientData.credits;
        const newCreditValue = currentCredits + topup.topupValue;
        return { ...clientData, credits: newCreditValue };
    }

    console.log("clientData from context", clientData)
    /* Conversations tracking */

    const monitorConversation = async (title, response, setResponse, setCheckState, setCost, setWorking, process) => {
        // console.log("Checking for answer...");
        // console.log(response, "response")
        const update = await check(response?.job_info.studio_id, response?.job_info.job_id);
        if (typeof update === 'object' && update.updates) {
            setCheckState(update);
            if (update?.updates?.[0]?.type === 'chain-success') {
                // console.log("Answer found!");

                const answer = update.updates[0].output.output.answer;
                const cost = calcCost(update.updates[0].output.credits_used);

                await spendCredits(cost, title);
                setCost(cost);

                try {
                    // console.log(answer, "answer")
                    process(answer);

                    setWorking(false);
                } catch (e) {
                    // Check if the error is a timeout error
                    if (e.name === 'TimeoutError' || e.message.includes('timeout')) {
                        // Continue polling if it's a timeout error
                        console.warn("Timeout error occurred, continuing polling:", e);
                        setTimeout(() => {
                            monitorConversation(title, response, setResponse, setCheckState, setCost, setWorking, process);
                        }, 1000);
                    } else {
                        // Stop polling if it's a non-timeout error
                        console.error("Non-timeout error occurred, stopping operation:", e);
                        triggerNotification({ message: "An unexpected error occurred!", severity: "error" });
                        setResponse(null);
                        setWorking(false);
                    }
                }

            } else {
                // console.log("setting timeout after agent still running")
                setTimeout(() => {
                    monitorConversation(title, response, setResponse, setCheckState, setCost, setWorking, process);
                }, 1000);
            }
        } else {
            // TODO: Handle ongoing errors to prevent endless loop that currently exists here
            // console.log("setting timeout after a failed check")
            setTimeout(() => {
                monitorConversation(title, response, setResponse, setCheckState, setCost, setWorking, process);
            }, 1000);
        }
    }

    const setConversation = (convoId) => {
        if (convoId) {
            const convo = conversations.find(c => c.id === convoId);
            setCurrentConversation(convo);
            return convo;
        }

        setCurrentConversation(null);
        return null;
    }

    useEffect(() => {
        const fetchConversations = async () => {
            const _conversations = await getClientConversationsForModule(client, activeModule);
            setConversations(_conversations);
        }

        if ((!conversations && clientData && activeModule) || triggerConversationsRefresh) {
            fetchConversations();
            triggerConversationsRefresh && setTriggerConversationsRefresh(false);
        }
    }, [activeModule, clientData, conversations, client, triggerConversationsRefresh])

    const startConversation = async (module, description, steps) => {
        const result = await createConversation(client, user.email, module, description, steps);
        // console.log(result);
        setCurrentConversation({ id: result.data.id, description, steps });
        setTriggerConversationsRefresh(true);
        return result;
    }

    const continueConversation = async (steps, index) => {
        const _steps = [...currentConversation.steps]
        if (!index) {
            _steps.push(...steps);
        } else {
            _steps[index] = steps[0];
        }
        const result = await updateConversation({ id: currentConversation.id, steps: _steps });
        // console.log(result);
        setCurrentConversation(convo => ({ ...convo, steps: _steps }));
        setTriggerConversationsRefresh(true);
        return result;
    }

    const pinConversation = async ({ id, pinned }) => {
        const result = await updateConversation({ id, pinned });
        setTriggerConversationsRefresh(true);
        return result;
    }

    const renameConversation = async ({ id, description }) => {
        const result = await updateConversation({ id, description });
        setTriggerConversationsRefresh(true);
        return result;
    }

    const editConversation = async ({ id, ...edit }) => {
        const result = await updateConversation({ id, ...edit });
        setTriggerConversationsRefresh(true);
        return result;
    }

    const removeConversation = async (conversationId, callback) => {
        const result = await deleteConversation(conversationId);
        setTriggerConversationsRefresh(true);
        callback(result);
        return result;
    }

    /* Data source management */

    const defineDataSource = (value) => {
        defineDataType('')
        setDataSource(value);
        const instances = clientData.platforms.filter(d => d.platform === value);
        setDataInstances(instances);
        if (instances.length > 1) {
            // Multiple instances defined - have user select one
        } else {
            // Only one instance defined - use that
            setDataInstance(0);
        }
    }

    const defineDataInstance = (value) => {
        setDataInstance(value);
    }

    const defineDataOptions = (value) => {
        setDataOptions(value);
    }

    const defineDataType = async (value, getData = false, query) => {
        setDataType(value);
        if (getData) {
            const data = await getDataByType(clientData.key, dataInstances[dataInstance].key, dataSource, value, query);
            if (String(data?.status).startsWith('2') && data.response) {
                setDataOptions(data.response);
                setDataFields(Object.keys(data.response[0]));
            } else {
                // TODO: Handle error
                // setNotification({message: "An unexpected error occurred!", severity: "error"});
                console.log("Problem getting data options", data);
            }
        }
    }

    const defineDataItem = (value) => {
        setDataItem(value);
    }

    const defineDataField = (value) => {
        setDataField(value);
    }

    const sendUpdatedData = async (result, exactMatch) => {
        toggleWorking(true);
        let body = {};
        if (dataItem) body.id = dataItem;
        if (dataField) {
            body[dataField] = result;
        } else {
            Object.keys(result).forEach(f => body[f] = result[f]);
        }

        if (exactMatch) {
            body = result;
        }

        const save = await updateData(clientData.key, dataInstances[dataInstance].key, dataSource, dataType, body);
        toggleWorking(false);
        return save;
    }

    // Breakpoints
    const xs = useMediaQuery('(min-width:0px)');
    const sm = useMediaQuery('(min-width:600px)');
    const md = useMediaQuery('(min-width:900px)');
    const lg = useMediaQuery('(min-width:1200px)');
    const xl = useMediaQuery('(min-width:1536px)');
    const breakpoints = useMemo(() => ({ xs, sm, md, lg, xl }), [xs, sm, md, lg, xl]);

    const pages = {
        tasks: {
            module: "tasks", label: 'Tasks Board', permission: isClient(), icon: icons.IconDiamond,
            description: 'Organise, prioritise, and track tasks in a streamlined interface. Stay on top of deadlines and project milestones.'
        },
        discovery: {
            module: "discovery", label: 'Discovery Board', permission: isClient(),
            description: 'Discover, organise, and prioritise innovative strategies. Turn concepts into actionable projects.'
        },
        build: {
            module: "netlifyBuild", label: 'Build', permission: isClient(), icon: icons.IconDiamond,
            description: 'Track and seamlessly initiate the process of building and launching your website.'
        },
        redirects: {
            module: "netlifyRedirects", label: 'Redirects', permission: isClient(), icon: icons.IconCaptivePortal,
            description: 'Manage and implement URL redirects to maintain your website’s SEO integrity.'
        },
        'article-writer': {
            module: "contentWriter", label: 'Article Writer', permission: isClient(), icon: icons.IconArticleWriter,
            description: 'Write SEO-optimised blog articles using factual, real-time research to enhance visibility.'
        },
        'content-scorer': { module: "contentScorer", label: 'AI Content Scorer', permission: isClient() },
        'product-description-without-web-research': { 
            module: "productDescriptionWithoutRAG", 
            label: 'Product Description - without web research', 
            permission: isClient(), icon: icons.IconProductDescriptionGenerator,
            description: 'Write product descriptions through image analysis and product specifications.'
        },
        'product-description-with-web-research': { 
            module: "productDescriptionWithRAG", 
            label: 'Product Description - with web research', 
            permission: isClient(), icon: icons.IconProductDescriptionGenerator,
            description: 'Write product descriptions enriched with web research to emphasise key features and specifications.'
        },
        'product-writer': {
            module: "productWriter", label: 'Product Title & Description Writer', permission: isClient(), icon: icons.IconProductDescriptionWriter,
            description: 'Write SEO-optimised product titles and descriptions using SKU data and real-time research.'
        },
        'image-scraper': {
            module: "aiImgScraper", label: 'Image Scraper', permission: isClient(), icon: icons.IconDiamond,
            description: 'Extract images efficiently from websites and online sources for your projects.'
        },
        'meta-data-optimiser': {
            module: "bulkMetaOptimiser", label: 'Meta Data Optimiser', permission: isClient(), icon: icons.IconMetaDataOptimiser,
            description: 'Optimise meta titles and descriptions to improve search engine rankings at scale.'
        },
        'image-alt-text-generator': {
            module: "imageAltTextGenerator", label: 'Image Alt Text Generator', permission: isClient(), icon: icons.IconImageAltTextGenerator,
            description: 'Generate SEO-friendly alt text for images to improve web accessibility and ranking in Google image searches.'
        },
        'help': {
            module: "docs", label: 'Help Center', permission: isClient(), icon: icons.IconHelp,
            description: 'Access a resource center with tutorials, FAQs, and support for all your needs.'
        },
        news: {
            module: "news", label: 'News', permission: isClient(), icon: icons.IconNews,
            description: 'Stay updated with industry trends, platform updates, and the latest feature releases.'
        },
        profile: { label: '', permission: isClient() },
        clients: { label: 'Clients', permission: isAdmin() },
        '/': { label: 'Home', permission: true, icon: icons.IconDashboard },
    }

    return (
        <IdentifyContext.Provider value={{
            identity: netlifyIdentity,
            atlassianWorking,
            blobsWorking,
            faunadbWorking,
            notify,
            triggerNotification,
            user,
            userChecked,
            roles,
            addRole,
            removeRole,
            loginAs,
            setClientData,
            setClient,
            // setLoginAs,
            triggerLoginAs,
            client,
            clientData,
            addClient,
            saveClient,
            removeClient,
            updateUserMeta,
            getCustomerId,
            isWorking,
            toggleWorking,
            transfer,
            dataTransfer,
            isAdmin,
            isClient,
            hasAccess,
            setModule,
            activeModule,
            addCredits,
            hasCredits,
            recalculateCredits,
            spendCredits,
            conversations,
            monitorConversation,
            setConversation,
            currentConversation,
            startConversation,
            continueConversation,
            removeConversation,
            dataSource,
            defineDataSource,
            dataInstances,
            dataInstance,
            defineDataInstance,
            dataType,
            defineDataType,
            dataOptions,
            defineDataOptions,
            dataItem,
            defineDataItem,
            dataFields,
            dataField,
            defineDataField,
            sendUpdatedData,
            pinConversation,
            renameConversation,
            editConversation,
            pages,
            ...breakpoints
        }}>
            {props.children}
        </IdentifyContext.Provider>
    )
}

export default IdentifyContext;

export { IdentityProvider }
